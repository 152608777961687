
import Widget from '@/components/Widget/Widget.vue'
import { Component, Ref, Watch } from 'vue-property-decorator'
import Profile from '@/models/Profile'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import profile_home_table_fields from './profile-home-table-fields'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
  },
})
export default class ProfileHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public profile: Profile = new Profile()

  public sort_by: string = 'updated_at'

  public sort_desc: boolean = true

  public query: string[] = []

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public modal: any = {
    delete: false,
  }

  public fields: any = []

  // dataTable field filters
  public fieldFilters: any = {}

  public query_settings: any = {
    company_fields: [
      {
        name: 'company',
        key: 'company_id',
      },
      {
        name: 'station',
        key: 'station_id',
        type: 'station',
      },
    ],
    custom_fields: [
      {
        name: 'station:n/a',
        value: 'station_id:',
      },
    ],
  }

  public show_filter_helper: boolean = false

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public profiles(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    return Profile.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  mounted() {
    this.loadFilters()
    this.fields = profile_home_table_fields
  }

  public confirmDelete(profile: Profile) {
    this.profile = profile
    this.modal.delete = true
  }

  public deleteUser() {
    this.profile.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public createProfile() {
    this.$router.push('/app/profile')
  }

  public editProfile(id: string) {
    this.$router.push(`/app/profile/${id}`)
  }

  public created() {
    this.records = this.profiles.length
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'profiles',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('profiles').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'profiles',
      data: null,
    })
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.filter_mode = 'exclusive'
    this.clearFilters()
    this.refresh()
  }
}
