
import {
  Component, Vue, Prop, Watch, Ref,
} from 'vue-property-decorator'
import Profile from '@/models/Profile'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import Company from '@/models/Company'
import AccessControl from './AccessControl.vue'

// @ts-ignore
@Component({
  // @ts-ignore
  components: {
    AccessControl,
    CompanyPicker,
    FormInput,
    FormWizard,
    TabContent,
  },
})
export default class ProfileForm extends ViewModel {
  @Ref() readonly formWizard: any

  @Prop({ default: null })
  public id!: string | null

  public profile: Profile = new Profile()

  public step: number = 1

  @Prop({
    default: 'full',
  })
  public view_mode!: string

  @Prop({
    default: '',
  })
  public new_id!: string

  @Prop({
    default: true,
  })
  public show_sidebar!: boolean

  @Prop({ default: '' })
  public company_id!: string

  @Prop({ default: '' })
  public station_id!: string

  @Watch('show_sidebar')
  public onSidebarStatus() {
    this.init()
  }

  @Watch('company_id')
  public onCompanyChange() {
    this.profile.company_id = this.company_id
  }

  @Watch('station_id')
  public onStationChange() {
    this.profile.company_id = this.company_id
    this.profile.station_id = this.station_id
  }

  @Watch('profile.company_id')
  public fetchCompany() {
    if (this.profile.company_id && this.profile.company.id != this.profile.company_id) {
      Company.find(this.profile.company_id).then(company => {
        if (company.id) this.profile.company = company
      })
    }
  }

  @Watch('profile.station_id')
  public fetchStation() {
    if (this.profile.station_id && this.profile.station.id != this.profile.station_id) {
      Company.find(this.profile.station_id).then(station => {
        if (station.id) {
          this.profile.station = station
        }
      })
    }
  }

  public mounted() {
    this.init()
  }

  public init() {
    if (this.id) {
      Profile.find(this.id).then(o => {
        if (o instanceof Profile) {
          this.profile = o

          setTimeout(() => {
            // @ts-ignore
            this.formWizard?.activateAll()
          }, 1000)
        } else {
          this.profile = new Profile()
        }
        this.applyRestriction()
      })
    } else {
      this.profile = new Profile()
      this.applyRestriction()
    }
  }

  public applyRestriction() {
    if (this.company_id != '') {
      this.profile.company_id = this.company_id
    }

    if (this.station_id != '') {
      this.profile.station_id = this.station_id
    }
  }

  public onSubmit() {
    this.loading = true
    this.profile
      .save()
      .then((response: any) => {
        this.loading = false
        if (response.status == 200) {
          if (this.view_mode == 'sidebar') {
            this.$emit('update:new_id', response.data.result.profile.id)
            this.init()
            this.$emit('update:show_sidebar', false)
          } else {
            this.$router.push({ name: 'Profiles' })
          }
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    if (this.view_mode == 'sidebar') {
      this.$emit('update:show_sidebar', false)
    } else {
      this.$router.push({ name: 'Profiles' })
    }
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }
}
