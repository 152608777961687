
import Widget from '@/components/Widget/Widget.vue'
import { Component, Prop } from 'vue-property-decorator'
import Profile from '@/models/Profile'
import ViewModel from '@/models/ViewModel'
import ProfileForm from './Components/ProfileForm.vue'

@Component({
  components: {
    Widget,
    ProfileForm,
  },
})
export default class ProfileEdit extends ViewModel {
  @Prop({ default: null })
  public id!: string | null;

  public title: string = 'Create Profile';

  public profile: Profile = new Profile();

  public created() {
    if (this.id) {
      this.title = 'Edit Profile'
    }
  }
}
